import React from 'react';
import Layout from '../components/layout';

function aboutMe() {
    return (
        <Layout>
            <div className="grid-item">
                <div className="grid-content">
                    <div id="aboutMeDiv">
                        <p>
                            I entered the software field because I love problem solving. Every business, organization, 
                            and even individual has a series of issues and situations for which they need innovative and quality solutions. Often, software 
                            can be this solution. With careful information gathering, understanding, and planning, I have been able to solve 
                            problems for clients, while tackling intricate technical details to ensure the product serves them well.
                        </p>
                        <p>
                            My first position as a developer was the equivalent of being thrown into the deep-end of a pool and being told to swim. 
                            The experience I gained was precious and thorough. I was more than just a full-stack developer: I was a full-stack
                            professional. My duties included those of a Business Analyst, Support Professional, Software Developer, Database Analyst,
                            Quality Assurance Technician, and Development Operations Engineer.
                        </p>
                        <p>
                            My experience has been wide-spread, but I specialize in providing web and mobile solutions. In a fast-paced world
                            constantly on-the-go, these applications are at our fingertips at any given moment. My knowledge of SQL, C#, Xamarin, 
                            and JavaScript (incl. React, Gatsby, Angular) provide the tools needed to expertly fit clients' needs. 
                        </p>
                        <p>
                            Additionally, I am a personable and outgoing individual. I pride myself on my ability to communicate with and 
                            understand others, whether discussing technical concepts with non-technical people or simply shooting the breeze. 
                            It is because of my transferable skills and software expertise I am able to be confident in my ability to deliver 
                            exactly what clients are looking for.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default aboutMe;